<template>
  <div>
    <user-info></user-info>
  </div>
</template>

<script>
import UserInfo from '@/frontend/components/profile-components/UserInfo.vue'

export default {
  components: {
    UserInfo,
  },
  data() {
    return {
    }
  },
}
</script>
